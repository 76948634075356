import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';

import { getCurrentDealEventStrObj } from '@utils/eventUtils';
import classes from './styles';
import LoadingPhrases from './LoadingPhrases';

const SkeletonInnerDealCard = () => {
  return (
    <>
      <Box sx={classes.imageWrapper}>
        <Box
          sx={{
            width: 140,
            height: 140,
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '3px',
            margin: '0px auto 8px auto',
            paddingLeft: '4px'
          }}
        >
          <Skeleton
            component="div"
            variant="rectangular"
            sx={{
              ...classes.imageMobile,
              ...classes.skeletonImageMobile
            }}
            width={140}
            height={140}
          />
        </Box>
      </Box>
      <CardContent
        sx={{
          ...classes.cardContent,
          ...classes.skeletonCardContent
        }}
      >
        <Skeleton
          sx={{
            marginBottom: '4px'
          }}
          variant="rectangular"
          height={18}
        />
        <Skeleton
          sx={{
            marginBottom: '4px'
          }}
          variant="rectangular"
          height={18}
          width="75%"
        />
        <Skeleton
          sx={{
            marginBottom: '4px'
          }}
          variant="rectangular"
          width="60%"
          height={22}
        />
        <Skeleton
          sx={{
            marginBottom: '6px'
          }}
          variant="rectangular"
          height={14}
          width="45%"
        />
        <Skeleton
          sx={{
            marginBottom: '6px'
          }}
          variant="rectangular"
          height={14}
          width="60%"
        />
        <Skeleton
          sx={{
            marginBottom: '6px',
            marginRight: '4px',
            marginTop: '4px'
          }}
          variant="rectangular"
          height={14}
          width="10%"
        />
      </CardContent>
      <CardActions sx={classes.actionButtons}>
        <Skeleton
          sx={classes.skeletonTextActions}
          variant="rectangular"
          height={30}
          width={78}
        />
        <Skeleton
          sx={{
            marginRight: '0px'
          }}
          variant="circular"
          height={34}
          width={34}
        />
        <Skeleton
          sx={{
            marginRight: '0px'
          }}
          variant="circular"
          height={34}
          width={34}
        />
      </CardActions>
    </>
  );
};

const SkeletonDealCard = ({
  index = 0,
  shouldRenderLoadingMessage,
  targetLoadingIndex = 0
}: {
  index?: number;
  shouldRenderLoadingMessage?: boolean;
  targetLoadingIndex?: number;
}) => {
  const currentEvent = getCurrentDealEventStrObj();
  const phrases = currentEvent?.customLoadingMessages || [
    'Finding the hottest Amazon deals for you...',
    'Scanning the aisles of savings...',
    'Fetching today’s best bargains...',
    'Hunting down exclusive discounts...',
    'Loading the latest steal-worthy offers...',
    'Sorting through endless deals to bring you the best...',
    'Unpacking amazing savings just for you...',
    'Cartwheeling through Amazon for top deals...',
    'Polishing up your perfect bargain...',
    'Finalizing the best deals on the web...'
  ];

  return (
    <Card
      key="test"
      raised
      sx={{
        ...classes.cardRoot,
        display: 'block !important',
        position: 'relative'
      }}
    >
      {index === targetLoadingIndex && shouldRenderLoadingMessage ? (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 10,
            right: 10,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1 // Ensure it overlays the Skeleton
          }}
        >
          <LoadingPhrases phrases={phrases} />
        </Box>
      ) : null}
      <SkeletonInnerDealCard />
    </Card>
  );
};

export default SkeletonDealCard;
