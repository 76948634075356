import React, { useMemo } from 'react';
import {
  Box,
  Skeleton,
  FormControlLabel,
  Checkbox,
  Typography
} from '@mui/material';
import times from 'lodash/times';
import { useTheme } from '@mui/system';
import trackUse from '@utils/trackUse';
import { logPostHogEvent } from '@utils/index';
import { useGetBrandFilters } from '@hooks/useDeals';
import { DealPostType } from '@types';

type BrandFilterProps = {
  setBrandFilters: (value: string[]) => void;
  brandFilters: string[];
  currentlyShowingDeals: DealPostType[];
  latestDealsLoading: boolean;
};

const BrandFilter: React.FC<BrandFilterProps> = ({
  setBrandFilters,
  brandFilters,
  currentlyShowingDeals,
  latestDealsLoading
}) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const { data: brands, isLoading: isLoadingBrands } = useGetBrandFilters();
  const loading = latestDealsLoading || isLoadingBrands;

  const mapOfBrandsWithCount = useMemo(() => {
    const map = new Map<string, number>();
    currentlyShowingDeals.forEach((deal) => {
      if (deal.brand) {
        map.set(deal.brand, (map.get(deal.brand) || 0) + 1);
      }
    });
    return map;
  }, [currentlyShowingDeals]);

  const getCountFromBrand = (brand: string) => {
    return mapOfBrandsWithCount.get(brand) || 0;
  };

  const displayBrands = useMemo(() => {
    // Only show brands that have more than 1 occurrence
    // Sort them by descending count
    return (brands || [])
      .filter((brand) => getCountFromBrand(brand) > 1)
      .sort((a, b) => getCountFromBrand(b) - getCountFromBrand(a));
  }, [brands, mapOfBrandsWithCount]);

  const handleCheckboxToggle = (brand: string) => {
    const deselected = brandFilters.includes(brand);

    // Analytics
    logPostHogEvent(`brand-chip`, {
      value: `${brand}-${deselected ? 'deselected' : 'selected'}`,
      type: 'ACTION'
    });
    trackUse({
      item: 'brand-chip',
      value: `${brand}-${deselected ? 'deselected' : 'selected'}`,
      type: 'ACTION'
    });

    const newBrandFilters = deselected
      ? brandFilters.filter((b) => b !== brand)
      : [...brandFilters, brand];

    // Add/remove brand from the filter
    setBrandFilters(newBrandFilters);
  };

  const renderModal = () => (
    <>
      <Typography id="brand-dialog-title" variant="overline">
        Brand
      </Typography>
      {/* Scrollable list with fade on top/bottom */}
      <Box
        id="scrollable"
        sx={{ position: 'relative', maxHeight: 180, overflow: 'auto' }}
      >
        {/* The list of brands */}
        {loading
          ? times(8).map((idx) => (
              <Skeleton
                key={idx}
                variant="rectangular"
                width="100%"
                height={32}
                sx={{ borderRadius: 1, mb: 1 }}
              />
            ))
          : displayBrands.map((brand) => {
              const checked = brandFilters.includes(brand);
              return (
                <FormControlLabel
                  key={brand}
                  sx={{
                    display: 'block',
                    marginLeft: 0,
                    marginBottom: 0
                  }}
                  control={
                    <Checkbox
                      checked={checked}
                      onChange={() => handleCheckboxToggle(brand)}
                      color="primary"
                      size="small"
                    />
                  }
                  label={
                    <>
                      {brand}{' '}
                      <Box component="span" sx={{ opacity: 0.6 }}>
                        ({getCountFromBrand(brand)})
                      </Box>
                    </>
                  }
                />
              );
            })}
        {/* Bottom Fade */}
        <Box
          sx={{
            position: 'sticky',
            bottom: 0,
            height: 20,
            background: `linear-gradient(rgba(255,255,255,0), ${
              isDarkMode ? '#121212' : '#fff'
            })`,
            pointerEvents: 'none'
          }}
        />
      </Box>
    </>
  );

  return (
    <>
      {renderModal()}
      {/* Trigger Button – you can style or rename this as appropriate */}
      {/* <Button
        variant="outlined"
        onClick={() => setModalOpen(true)}
        startIcon={
          brandFilters.length > 0 ? <Avatar>{brandFilters.length}</Avatar> : null
        }
      >
        {brandFilters.length <= 1 ? 'Brand' : 'Brands'}
      </Button> */}
    </>
  );
};

export default BrandFilter;
