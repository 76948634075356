import React from 'react';
import { Box, FormControl, Select, MenuItem, Typography } from '@mui/material';
import { logPostHogEvent } from '@utils/index';
import trackUse from '@utils/trackUse';

const PercentageFilter = ({
  percentOff,
  setPercentOff
}: {
  percentOff: number | null;
  setPercentOff: (value: number | null) => void;
}) => {
  const applyPercentageSelection = (pc: number | null) => {
    logPostHogEvent('filter-chip-percentage', {
      value: `${pc}`,
      type: 'ACTION'
    });
    trackUse({
      item: `filter-chip-percentage`,
      value: `${pc}`,
      type: 'ACTION'
    });

    setPercentOff(pc);
  };

  return (
    <Box sx={{ margin: '8px 8px 8px 0px' }}>
      <Typography variant="overline">Percentage</Typography>
      <FormControl fullWidth size="small">
        <Select
          value={percentOff ?? 'null'}
          onChange={(event) =>
            applyPercentageSelection(
              event.target.value !== 'null' ? Number(event.target.value) : null
            )
          }
        >
          <MenuItem value="null">No filter</MenuItem>
          <MenuItem value="30">30% off or more</MenuItem>
          <MenuItem value="40">40% off or more</MenuItem>
          <MenuItem value="50">50% off or more</MenuItem>
          <MenuItem value="60">60% off or more</MenuItem>
          <MenuItem value="70">70% off or more</MenuItem>
          <MenuItem value="80">80% off or more</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export default PercentageFilter;
