import React from 'react';
import {
  Box,
  Chip,
  Slider,
  useTheme,
  useMediaQuery,
  Typography
} from '@mui/material';
import { formatPrice, logPostHogEvent } from '@utils/index';
import trackUse from '@utils/trackUse';

// Transform slider value (0-100) to price value (0 - maxPrice)
const transformValueFunc = (value: number, maxPrice: number): number => {
  if (value <= 50) {
    // Linear scaling for the first half (0-50 => 0-100)
    return (value / 50) * 100;
  }
  // Sliding scale for the second half (50-100 => 100-250)
  const remainingValue = value - 50;
  return 100 + (remainingValue / 50) * (maxPrice - 100);
};

// Transform price value (0 - maxPrice) back to slider value (0-100)
const inverseTransformValueFunc = (value: number, maxPrice: number): number => {
  if (value <= 100) {
    // Linear scaling for the first half (0-100 => 0-50)
    return (value / 100) * 50;
  }
  // Sliding scale for the second half (100-250 => 50-100)
  const remainingValue = value - 100;
  return 50 + (remainingValue / (maxPrice - 100)) * 50;
};

const transformValue = (value: number, maxPrice: number): number => {
  return Math.round(transformValueFunc(value, maxPrice));
};

const inverseTransformValue = (value: number, maxPrice: number): number => {
  return Math.round(inverseTransformValueFunc(value, maxPrice));
};

const PriceFilter = ({
  underPrice,
  overPrice,
  setUnderPrice,
  setOverPrice,
  maxPrice = 250
}: {
  underPrice: number | null;
  overPrice: number | null;
  setUnderPrice: (value: number | null) => void;
  setOverPrice: (value: number | null) => void;
  maxPrice?: number;
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const underPriceValue = Math.min(underPrice || maxPrice, maxPrice);
  const localUnderPrice = inverseTransformValue(underPriceValue, maxPrice);
  // localOverPrice is the slider position:
  const localOverPrice = inverseTransformValue(overPrice || 0, maxPrice);

  const applyPriceRange = (
    newOverPrice: number | null,
    newUnderPrice: number | null
  ) => {
    logPostHogEvent('filter-chip-price', {
      value: `${newOverPrice || 0} - ${newUnderPrice || maxPrice}`,
      type: 'ACTION'
    });
    trackUse({
      item: `filter-chip-price`,
      value: `${newOverPrice || 0} - ${newUnderPrice || maxPrice}`,
      type: 'ACTION'
    });

    const finalOverPrice =
      newOverPrice !== 0 ? transformValue(newOverPrice, maxPrice) : null;
    const finalUnderPrice =
      newUnderPrice !== 100 ? transformValue(newUnderPrice, maxPrice) : null;

    setOverPrice(finalOverPrice);
    setUnderPrice(finalUnderPrice);
  };

  const handleSliderChange = (_event: Event, newValue: number | number[]) => {
    if (Array.isArray(newValue)) {
      applyPriceRange(newValue[0], newValue[1]);
    }
  };

  const renderSlider = () => {
    return (
      <Slider
        value={[localOverPrice, localUnderPrice]}
        onChange={handleSliderChange}
        valueLabelDisplay="auto"
        valueLabelFormat={(value) => {
          return `${formatPrice(transformValue(value, maxPrice))}`;
        }} // Custom tooltip text
        min={0}
        max={100} // Linear scale maximum
        step={1}
        sx={
          isMobile
            ? {
                '& .MuiSlider-markLabel': {
                  fontSize: '12px' // Adjust the size of the mark labels
                },
                '& .MuiSlider-valueLabel': {
                  fontSize: '14px' // Adjust the size of the value label (tooltip)
                }
              }
            : {}
        }
        marks={
          isMobile
            ? [
                { value: 0, label: '$0' },
                {
                  value: 50,
                  label: `${formatPrice(transformValue(50, maxPrice))}`
                },
                { value: 100, label: `${formatPrice(maxPrice)}+` }
              ]
            : [
                { value: 0, label: '$0' },
                { value: 100, label: `${formatPrice(maxPrice)}+` }
              ]
        }
      />
    );
  };

  return (
    <Box marginBottom="4px">
      <Typography variant="overline">Price</Typography>
      <Box>
        <Box>
          {[1, 2, 5, 10, 20, 50].map((price) => {
            return (
              <Chip
                key={price}
                sx={{
                  marginRight: '6px',
                  marginBottom: '6px'
                }}
                label={`Under ${formatPrice(price)}`}
                onClick={() => {
                  applyPriceRange(0, inverseTransformValue(price, maxPrice));
                }}
              />
            );
          })}
        </Box>
        <Box
          sx={{
            alignItems: 'center',
            // maxWidth: isMobile ? '100%' :'85%',
            margin: isMobile ? '8px 20px 8px 8px' : '0px 30px 0px 10px'
          }}
        >
          {' '}
          <Box>
            {' '}
            <Typography variant="caption" color="text.secondary">
              {`Price Range: ${formatPrice(
                transformValue(localOverPrice || 0, maxPrice)
              )} — ${
                transformValue(localUnderPrice || maxPrice, maxPrice) ===
                maxPrice
                  ? `$${maxPrice}+`
                  : formatPrice(
                      transformValue(localUnderPrice || maxPrice, maxPrice)
                    )
              }`}
            </Typography>
          </Box>
          {renderSlider()}
        </Box>
      </Box>
    </Box>
  );
};

export default PriceFilter;
