import React from 'react';
import { Box, Chip, Typography } from '@mui/material';
import trackUse from '@utils/trackUse';
import { logPostHogEvent } from '@utils/index';
import ShowIcon from '@components/ShowIcon';
import { FilterChipConfigType } from '@types';

const CategoryChip = ({
  categoryOptions
}: {
  categoryOptions: FilterChipConfigType;
}) => {
  const handleChipClick = (filter: any) => {
    logPostHogEvent('filter-chip', {
      value: `${filter.label} - ${filter.selected ? 'DESELECT' : 'SELECT'}`,
      type: 'ACTION'
    });
    trackUse({
      item: `filter-chip`,
      value: `${filter.label} - ${filter.selected ? 'DESELECT' : 'SELECT'}`,
      type: 'ACTION'
    });

    // Call the provided onClick handler
    filter.onClick();
  };

  return (
    <Box mb={2}>
      <Typography variant="overline">Category</Typography>

      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 1 // Add spacing between chips
        }}
      >
        {categoryOptions.map((filter) => (
          <Chip
            key={filter.label}
            label={filter.label}
            onClick={() => handleChipClick(filter)}
            sx={{
              cursor: 'pointer',
              marginRight: '4px'
            }}
            icon={
              <ShowIcon
                icon={filter.icon}
                // use style for not mui libraries
                style={{ color: filter.iconColor, fontSize: '24px' }}
              />
            }
            variant={filter.selected ? 'filled' : 'outlined'}
            aria-pressed={filter.selected} // Accessibility for toggle buttons
            onDelete={
              filter.selected ? () => handleChipClick(filter) : undefined
            }
          />
        ))}
      </Box>
    </Box>
  );
};

export default CategoryChip;
