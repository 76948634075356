export default {
  // needs to be multiples of 2 and 3 to load balanced
  initialNumDeals: 18,
  initialLoadingSkeletons: 18,
  loadPerScroll: 24,
  loadingSkeletons: 24,
  fakeLoadTimeout: 1000,
  strategyFilter: '',
  defaultSort: 'newest',
  defaultOnlyFavorites: false,
  defaultOnlySponsored: false,
  defaultOnlyRatePromotion: false,
  defaultUnderPrice: null,
  defaultOverPrice: null,
  defaultPercentOff: null,
  defaultOnlyShowNew: false,
  defaultOnlyCoupons: false,
  defaultOnlySubscribeSave: false,
  defaultTrending: false,
  defaultPrimeDay: false,
  defaultOnlyShowAveragePriceLower: false,
  defaultTotalSoldLessThan: null,
  defaultTotalSoldMoreThan: null,
  defaultOnlyShowOlderThanDays: null,
  defaultBrandFilters: []
};
